<template>
    <div class="vx-col w-5/6 ml-auto mr-auto">
        <span>
            <h4 class="mb-5" v-if="discountId!=null">Edit Discount</h4>
            <h4 class="mb-5" v-else>Create Discount</h4>
        </span>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Code" name="Code" v-model="data.code" readonly/>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-input v-validate="'required'" class="w-full" label="Name" name="Name" v-model="data.name" />
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('Name')"
                    >{{ errors.first('Name') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-textarea maxlength ="255" class="w-full" label="Notes" name="Notes" v-model="data.notes" />
            </div>
        </div>
        <div class="vx-row mb-6" style="display: flex">
            <div class="vx-col w-1/2" style="display: flex">
                <label class="vs-input--label">Model</label>
                <ul class="ml-6">
                    <li>
                        <vs-radio v-validate="'required'" name="model" v-model="data.model" vs-value="Final">Final</vs-radio>
                    </li>
                    <li>
                        <vs-radio v-validate="'required'" name="model" v-model="data.model" vs-value="Step Ladder">Step Ladder</vs-radio>
                    </li>
                    <span
                        class="text-danger text-sm"
                        v-show="errors.has('model')"
                        >{{ errors.first('model') }}
                    </span>
                </ul>
            </div>
            <div class="vx-col w-1/2" style="display: flex">
                <label class="vs-input--label">Unit</label>
                <ul class="ml-6">
                    <li>
                        <vs-radio v-validate="'required'" name="unit" v-model="data.unit" vs-value="Total">Total</vs-radio>
                    </li>
                    <li>
                        <vs-radio v-validate="'required'" name="unit" v-model="data.unit" vs-value="Line">Line</vs-radio>
                    </li>
                    <span
                        class="text-danger text-sm"
                        v-show="errors.has('unit')"
                        >{{ errors.first('unit') }}
                    </span>
                </ul>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Valid From</label>
                <datepicker :disabled="read" v-validate="'required'" name="valid from" :inline="false" v-model="data.valid_from" placeholder="Select Date"></datepicker>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('valid from')"
                    >{{ errors.first('valid from') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Valid To</label>
                <datepicker :disabled="read" v-validate="'required'" name="valid to" :inline="false" v-model="data.valid_to" placeholder="Select Date"></datepicker>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('valid to')"
                    >{{ errors.first('valid to') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Currency Code</label>
                <v-select v-validate="'required'" name="currency code" v-model="data.currency_code" label="Code" placeholder="Select currency code" :options="currency" @input="setSelectedCurrencyCode">
                    <template slot="option" slot-scope="option">
                        <div style="align-items: center;"><span>{{option.Code}}</span></div>
                    </template>
                </v-select>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('currency code')"
                    >{{ errors.first('currency code') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Discount Cap</label>
                <vx-input-group>
                    <template slot="append">
                        <div class="append-text bg-primary">
                            <span>{{data.currency_code}}</span>
                        </div>
                    </template>
                    <vs-input name="discount_cap" @keypress="isNumber($event)" v-model="data.discount_cap" @input="formatNumber()" min="0" max="4" class="w-full" placeholder=""/>
                </vx-input-group>
            </div>
        </div>
        <!-- <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Status Lock</label>
                <vs-switch 
                    color="success"
                    icon-pack="feather"
                    vs-icon-on="icon-check-circle"
                    vs-icon-off="icon-slash"
                    v-model="data.is_locked"
                >
                    <span slot="on">Lock</span>
                    <span slot="off">Unlock</span>
                </vs-switch>
            </div>
        </div> -->
        <!-- <div v-if="data.is_locked" class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-input class="w-full" label="User" name="User" v-model="data.id_user" />
            </div>
        </div> -->
        <vs-table 
            stripe
            border
            description 
            :sst="true"
            :data="steps"
            class="vx-row mb-6 ml-1 mr-1"
        >
            <template slot="thead">
                <vs-th>Step</vs-th>
                <vs-th>From</vs-th>
                <vs-th>To</vs-th>
                <vs-th width="20%">Discount</vs-th>
                <vs-th width="20%">Cap</vs-th>
                <vs-th></vs-th>
            </template>
            <template :data="steps">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in steps">
                    <vs-td>{{tr.step}}</vs-td>
                    <vs-td>
                        <vx-input-group>
                            <template slot="append">
                                <div class="append-text bg-primary">
                                    <span>{{data.currency_code}}</span>
                                </div>
                            </template>
                            <vs-input name="amount_from" v-validate="'required'" @keypress="isNumber($event)" @input="handleStep(indextr, 0)" v-model="tr.from" min="0" class="w-full" placeholder="amount"/>
                            <span
                                class="text-danger text-sm"
                                v-show="errors.has('amount_from')"
                                >{{ errors.first('amount_from') }}
                            </span>
                        </vx-input-group>
                    </vs-td>
                    <vs-td>
                        <vx-input-group>
                            <template slot="append">
                                <div class="append-text bg-primary">
                                    <span>{{data.currency_code}}</span>
                                </div>
                            </template>
                            <vs-input name="amount_to" v-validate="'required'" @keypress="isNumber($event)" @input="handleStep(indextr, 1), setDiscount(indextr)" v-model="tr.to" min="0" max="4" class="w-full" placeholder="amount"/>
                            <span
                                class="text-danger text-sm"
                                v-show="errors.has('amount_to')"
                                >{{ errors.first('amount_to') }}
                            </span>
                        </vx-input-group>
                    </vs-td>
                    <vs-td>
                        <vs-input @keypress="isNumber($event)" @input="setDiscountAmount(indextr)" icon-pack="feather" icon="icon-percent" icon-after v-model="tr.discount" class="w-full mb-1" placeholder="percent"/>
                        <vs-input @keypress="isNumber($event)" @input="handleStep(indextr, 2), setDiscountPercent(indextr)" placeholder="amount" v-model="tr.discount_amount" class="w-full"/>
                    </vs-td>
                    <vs-td>
                        <vx-input-group>
                            <template slot="append">
                                <div class="append-text bg-primary">
                                    <span>{{data.currency_code}}</span>
                                </div>
                            </template>
                           <vs-input name="discount_line_cap" @keypress="isNumber($event)" @input="handleStep(indextr, 3)" v-model="tr.discount_line_cap" min="0" max="4" class="w-full" placeholder=""/>
                        </vx-input-group>
                    </vs-td>
                    <vs-td>
                        <vs-button
                            v-if="steps[indextr].button=='minus'"
                            style="display: table-cell;"
                            v-on:click="handleRemoveItem(indextr)"
                            color="danger"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-minus"
                        ></vs-button>
                        <vs-button
                            v-else
                            style="display: table-cell;"
                            v-on:click="handleAddItem()"
                            color="success"
                            type="filled"
                            icon-pack="feather"
                            icon="icon-plus"
                        ></vs-button>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                 <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
            </div>
        </div>
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect
    },
    props: {
        discountId: {
            type: Number
        }
    },
    created() {
        this.getCode();
        this.getCurrencyCode();
    },
    data() {
        return this.initialState();
    },
    methods: {
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        handleAddItem(){
            var counter = this.steps.length
            var to_before = ''
            if (this.steps[counter-1].to.length != 0 && this.steps[counter-1].to.toString().split(".").length == 1) {
                to_before = (parseFloat(this.steps[counter-1].to.replace(/,/g, "")) + 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else if (this.steps[counter-1].to.length != 0 && this.steps[counter-1].to.toString().split(".").length >= 2){
                to_before = (parseFloat(this.steps[counter-1].to.replace(/,/g, "")) + 0.001).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            this.steps.push({
                step: counter+1,
                from: to_before,
                to: '',
                discount: '',
                discount_amount: '',
                discount_line_cap: 0,
                button: "minus"
            })
        },
        handleRemoveItem(indextr){
            this.steps.splice(indextr, 1)
            for (var i = indextr; i < this.steps.length; i++) {
                this.steps[i]["step"] -= 1
                this.steps[i].from = (parseFloat(this.steps[i-1].to.replace(/\./g, "")) + 0.001).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            } 
        },
        initialState() {
            return {
                read: false,
                steps: [
                    {
                        step: 1,
                        from: '',
                        to: '',
                        discount: '',
                        discount_amount: '',
                        discount_line_cap: 0
                    }
                ],
                currency: [],
                data: {
                    code: "",
                    name: "",
                    notes: "",
                    model: "",
                    unit: "",
                    valid_from: "",
                    valid_to: "",
                    discount_cap: 0,
                    is_locked: false,
                    is_locked1: "0",
                    currency_code: ""
                }
            };
        },
        setSelectedCurrencyCode(value) {
            this.data.currency_code = value.Code;
        },
        getCurrencyCode(){
            this.$http
            .get("/api/v1/master/currency").then(resp => {
                if (resp.status == "success") {
                    this.currency = resp.data.records;
                }
            });
        },
        handleSubmit() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.$vs.loading();
                    var _this = this
                    if(this.data.discount_cap !== 0){
                        this.data.discount_cap = parseFloat(this.data.discount_cap.replace(/,/g, ""))
                    }
                    
                    this.steps.forEach(function(element, index){
                        _this.steps[index].from = parseFloat(element.from.replace(/,/g, ""))
                        _this.steps[index].to = parseFloat(element.to.replace(/,/g, ""))
                        _this.steps[index].discount = parseFloat(element.discount)
                        _this.steps[index].discount_amount = parseFloat(element.discount_amount.replace(/,/g, ""))
                        if(_this.steps[index].discount_line_cap !== 0){
                            _this.steps[index].discount_line_cap = parseFloat(element.discount_line_cap.replace(/,/g, ""))
                        }
                    })

                    if (this.discountId) {
                        this.putData();
                    } else {
                        this.postData();
                    }
                }
            });
        },
        formatNumber() {
            if(this.data.discount_cap.replace(/,/g, "").length < 16){
                var str = this.data.discount_cap.toString().split(".");
                if (str.length > 2) {
                    this.data.discount_cap = this.data.discount_cap
                    .toString()
                    .replace(/\.+$/, "");
                }

                if (str.length == 2) {
                    if (str[1].length > 3) {
                    this.data.discount_cap = this.data.discount_cap
                        .toString()
                        .slice(0, -1);
                    }
                }
                this.data.discount_cap = this.data.discount_cap.replace(/,/g, "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                this.data.discount_cap = this.data.discount_cap.toString().slice(0, -1)
            }
        },
        handleStep(index, field) {
            if (field == 0){
                if(this.steps[index].from.replace(/,/g, "").length < 16){
                    var str = this.steps[index].from.toString().split(".");
                    if (str.length > 2) {
                        this.steps[index].from = this.steps[index].from
                        .toString()
                        .replace(/\.+$/, "");
                    }

                    if (str.length == 2) {
                        if (str[1].length > 3) {
                        this.steps[index].from = this.steps[index].from
                            .toString()
                            .slice(0, -1);
                        }
                    }
                    this.steps[index].from = this.steps[index].from.replace(/,/g, "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    this.steps[index].from = this.steps[index].from.toString().slice(0, -1);
                }
            } else if (field == 1) {
                if(this.steps[index].to.replace(/,/g, "").length < 16){
                    var str1 = this.steps[index].to.toString().split(".");
                    if (str1.length > 2) {
                        this.steps[index].to = this.steps[index].to
                        .toString()
                        .replace(/\.+$/, "");
                    }

                    if (str1.length == 2) {
                        if (str1[1].length > 3) {
                        this.steps[index].to = this.steps[index].to
                            .toString()
                            .slice(0, -1);
                        }
                    }

                    this.steps[index].to = this.steps[index].to.replace(/,/g, "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    this.steps[index].to = this.steps[index].to.toString().slice(0, -1);
                }
            } else if (field == 2){
                if(this.steps[index].discount_amount.replace(/,/g, "").length < 16){
                    var str2 = this.steps[index].to.toString().split(".");
                    if (str2.length > 2) {
                        this.steps[index].discount_amount = this.steps[index].discount_amount
                        .toString()
                        .replace(/\.+$/, "");
                    }

                    if (str2.length == 2) {
                        if (str2[1].length > 3) {
                        this.steps[index].discount_amount = this.steps[index].discount_amount
                            .toString()
                            .slice(0, -1);
                        }
                    }
                    this.steps[index].discount_amount = this.steps[index].discount_amount.replace(/,/g, "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    this.steps[index].discount_amount = this.steps[index].discount_amount.toString().slice(0, -1);
                }
            } else {
                if(this.steps[index].discount_line_cap.replace(/,/g, "").length < 16){
                    var str3 = this.steps[index].to.toString().split(".");
                    if (str3.length > 2) {
                        this.steps[index].discount_line_cap = this.steps[index].discount_line_cap
                        .toString()
                        .replace(/\.+$/, "");
                    }

                    if (str3.length == 2) {
                        if (str3[1].length > 3) {
                        this.steps[index].discount_line_cap = this.steps[index].discount_line_cap
                            .toString()
                            .slice(0, -1);
                        }
                    }
                    this.steps[index].discount_line_cap = this.steps[index].discount_line_cap.replace(/,/g, "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    this.steps[index].discount_line_cap = this.steps[index].discount_line_cap.toString().slice(0, -1);
                }
            }
        },
        setDiscountAmount(index) {
            if(this.steps[index].discount > 100){
                this.steps[index].discount = '100';
            }

            var discount = parseFloat(parseFloat(this.steps[index].discount) / 100).toFixed(3)
            var to = parseFloat(this.steps[index].to.replace(/,/g, ""))

            if(this.steps[index].to.replace(/,/g, "").length == 0){
                this.steps[index].discount_amount = ''
            } else {
                this.steps[index].discount_amount = (discount * to).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        setDiscountPercent(index) {
            if(this.steps[index].to.replace(/,/g, "").length == 0 || this.steps[index].discount_amount.replace(/,/g, "").length == 0){
                this.steps[index].discount = ''
            } else {
                this.steps[index].discount = parseFloat(parseFloat((this.steps[index].discount_amount.replace(/,/g, "") / parseFloat(this.steps[index].to.replace(/,/g, ""))) * 100).toFixed(3))
            }
        },
        setDiscount(index) {
            var discount = this.steps[index].discount
            var discount_amount = this.steps[index].discount_amount.toString().replace(/,/g, "")
            var to = parseFloat(this.steps[index].to.replace(/,/g, ""))

            if(typeof this.steps[index+1] !== 'undefined' && this.steps[index].to.toString().split(".").length == 1) {
                this.steps[index+1].from = (parseFloat(this.steps[index].to.replace(/,/g, "")) + 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            } else if(typeof this.steps[index+1] !== 'undefined' && this.steps[index].to.toString().split(".").length > 1){
                this.steps[index+1].from = (parseFloat(this.steps[index].to.replace(/,/g, "")) + 0.001).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }

            if(discount.length == 0 && discount_amount.length == 0){
                this.steps[index].discount = ''
                this.steps[index].discount_amount = ''
            } else if(discount.length != 0) {
                this.steps[index].discount_amount = parseFloat(to * parseFloat(discount / 100).toFixed(3)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        paramData() {
            return {
                discount_id: this.discountId,
                code: this.data.code,
                name: this.data.name,
                notes: this.data.notes,
                model: this.data.model,
                unit: this.data.unit,
                valid_from: this.data.valid_from,
                valid_to: this.data.valid_to,
                discount_cap: this.data.discount_cap,
                is_locked: this.data.is_locked1,
                currency_code: this.data.currency_code,
                steps: this.steps
            };
        },
        postData() {
            this.$http
                .post("/api/v1/master/discount", this.paramData())
                .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.handleClose();
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: "New Discount Created",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        getCode() {
            this.$vs.loading();
            if (!this.discountId) {
                this.$http
                .get("/api/v1/setting/master-number-code/DC")
                .then((resp) => {
                if (resp.status == "success") {
                    this.data.code = resp.data;
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
                });
                console.log(this.data.code)
                if (this.data.code == ""){
                    this.$http
                    .get("/api/v1/master/discount/code")
                    .then(resp => {
                        if (resp.code == 200) {
                            this.data.code = resp.data
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle"
                            });
                        }
                    })
                    .catch(error => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
                }
            }
        },
        putData() {
        this.$http
            .put("/api/v1/master/discount/" + this.discountId, this.paramData())
            .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
                this.handleClose();
                this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: "Discount Updated",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("close");
        },
        getData() {
        this.$vs.loading();
            if (this.discountId) {
                this.$http
                .get("/api/v1/master/discount/" + this.discountId)
                .then(resp => {
                    this.$vs.loading.close();
                    var _this = this
                    if (resp.code == 200) {
                        this.data.code = resp.data.code;
                        this.data.name = resp.data.name;
                        this.data.notes = resp.data.notes;
                        this.data.model = resp.data.model;
                        this.data.unit = resp.data.unit;
                        this.data.valid_from = resp.data.valid_from;
                        this.data.valid_to = resp.data.valid_to;
                        this.data.currency_code = resp.data.currency_code;
                        this.read = (resp.data.price_rule_usage > 0) ? true : false;
                        
                        if(resp.data.is_locked == "1"){
                            this.data.is_locked = true;
                        }                        
                        this.data.discount_cap = resp.data.discount_cap.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        
                        resp.data.steps.forEach(function(element, index){
                            var btn = "minus"
                            if(index == 0){
                                btn = "plus"
                            }
                            _this.steps.push({
                                from: element.from.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                to: element.to.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                step: element.step,
                                discount: element.discount,
                                discount_amount: element.discount_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                discount_line_cap: element.discount_line_cap.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                button: btn
                            })
                        })
                        this.steps.shift();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                })
                .catch(error => {
                    this.$vs.loading.close();
                    console.log(error);
                });
            }
            this.$vs.loading.close();
        },
        
    },
    mounted() {
        if (this.discountId) {
            this.getData();
        }
    },
    computed: {},
    watch: {
        discountId() {
            Object.assign(this.$data, this.initialState());
            this.getData();
            this.getCode();
            this.getCurrencyCode();
        },
        "data.is_locked": function(val) {
            if(val == true){
                this.data.is_locked1 = "1"
            } else {
                this.data.is_locked1 = "0"
            }
        },
    }
};
</script>